<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
                v-model="dialogEdit"
                max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div class="admin_section_header">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="emptyData"
                        >
                            Добавить объект
                        </v-btn>
                        <v-text-field
                            class="text-fld"
                            label="Поиск по названию"
                            v-model="searchName"
                        ></v-text-field>
                        <v-select
                            label="Поиск по типу"
                            v-model="searchType"
                            :items="object_types"
                            :menu-props="{ maxHeight: '400' }"
                            multiple
                            persistent-hint
                            item-text="name"
                            item-value="id"
                            class="text-fld"
                        ></v-select>
                        <v-select
                            label="Поиск по категории"
                            v-model="searchCategory"
                            :items="categories"
                            :menu-props="{ maxHeight: '400' }"
                            multiple
                            persistent-hint
                            item-text="name"
                            item-value="id"
                        ></v-select>
                        <v-select
                            label="Поиск по району"
                            v-model="searchRegion"
                            :items="regions"
                            :menu-props="{ maxHeight: '400' }"
                            multiple
                            persistent-hint
                            item-text="name"
                            item-value="id"
                        ></v-select>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="doSearch"
                        >
                            Искать
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="unSearch"
                        >
                            Очистить поиск
                        </v-btn>
                    </div>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Название"
                                v-model="editedItem.name"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-select
                                label="Тип"
                                v-model="editedItem.types"
                                :items="object_types"
                                :menu-props="{ maxHeight: '400' }"
                                multiple
                                persistent-hint
                                item-text="name"
                                item-value="id"
                                @change="haveEditorChanges = true"
                            ></v-select>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-select
                                label="Категория"
                                v-model="editedItem.categories"
                                :items="categories"
                                :menu-props="{ maxHeight: '400' }"
                                multiple
                                persistent-hint
                                item-text="name"
                                item-value="id"
                                @change="haveEditorChanges = true"
                            ></v-select>
                        </v-card-text>
                    </v-card>
                    <!--<v-card color="basil" flat>
                        <v-card-text>
                            <v-file-input
                                label="Фото"
                                accept="image/jpeg, image/png"
                                v-model="editedItem.newPreview"
                                @change="haveEditorChanges = true"
                            ></v-file-input>
                            <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.id > 0">
                                <v-img
                                    :src="$store.state.api + editedItem.preview + '?v=' + Date.now()"
                                    max-height="200"
                                    :contain="true"
                                />
                            </div>
                        </v-card-text>
                    </v-card> -->
                    <v-card color="basil" flat>
                        <v-card-text
                            class="flex-column"
                        >
                            <div class="text_field_with_button">
                                <v-file-input
                                    label="Главное изображение"
                                    accept="image/jpeg, image/png"
                                    v-model="editedItem.newPreview"
                                    class="text_field_short"
                                    @change="haveEditorChanges = true"
                                ></v-file-input>
                                <v-btn
                                    v-show="editedItem.havePreview"
                                    color="primary"
                                    dark
                                    @click="removePreview"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                            <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.id > 0">
                                <v-img
                                    :src="$store.state.api + editedItem.preview + '?v=' + Date.now()"
                                    max-height="200"
                                    :contain="true"
                                />
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-select
                                label="Район"
                                v-model="editedItem.regions"
                                :items="regions"
                                :menu-props="{ maxHeight: '400' }"
                                multiple
                                persistent-hint
                                item-text="name"
                                item-value="id"
                                @change="haveEditorChanges = true"
                            ></v-select>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Местоположение"
                                v-model="editedItem.location"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Границы территории"
                                v-model="editedItem.area"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Зоны охраны"
                                v-model="editedItem.guard_zone"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Защитные зоны"
                                v-model="editedItem.protection_zone"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-checkbox
                                label="В избранном"
                                v-model="editedItem.favorite"
                                @change="haveEditorChanges = true"
                            ></v-checkbox>
                        </v-card-text>
                    </v-card>
                    <v-card
                        v-if="editedItem.favorite"
                        color="basil"
                        flat
                    >
                        <v-card-text>
                            <v-text-field
                                label="Анонс"
                                v-model="editedItem.anounce"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card
                        v-if="editedItem.favorite"
                        color="basil"
                        flat
                    >
                        <v-card-text>
                            <v-autocomplete
                                label="Страница"
                                v-model="editedItem.page"
                                :items="pages_list"
                                :menu-props="{ maxHeight: '400' }"
                                persistent-hint
                                item-text="name"
                                item-value="id"
                                @change="haveEditorChanges = true"
                            ></v-autocomplete>
                        </v-card-text>
                    </v-card>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <file-uploader ref="uploader" />
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление объекта</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить выбранный объект?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить объект"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить объект"
            >
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import FileUploader from '@c/FileUploader.vue';
export default {
    name: 'Objects',
    components: {
        FileUploader
    },
    data() {
        return {
            list: [],
            object_types: [],
            pages_list: [],
            regions: [],
            categories: [],
            user: 0,
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Название',
                    sortable: false,
                },
                {
                    value: 'types',
                    text: 'Тип объекта',
                    sortable: false,
                },
                {
                    value: 'regions',
                    text: 'Район',
                    sortable: false,
                },
                {
                    value: 'categories',
                    text: 'Категории',
                    sortable: false,
                },
                {
                    value: 'favorite',
                    text: 'В избранном',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [10, 20, 50, 100, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                userId: -1,
                types: [],
                regions: [],
                categories: [],
                page: -1,
                favorite: false,
                anounce: '',
                location: '',
                area: '',
                guard_zone: '',
                protection_zone: '',
                date: '',
                visit: 0,
                preview: [],
                newPreview: [],
                removePreview: false,
                havePreview: false,
            },
            dialogRemove: false,
            itemToRemove: -1,
            curTab: null,
            haveEditorChanges: false,
            searchName: '',
            searchType: [],
            searchCategory: [],
            searchRegion: [],
        };
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        const url = this.$route.query;
        if (url.name !== undefined) {
            this.searchName = url.name;
        }
        if (url.type !== undefined) {
            if (Array.isArray(url.type)) {
                url.type.forEach((itm, ind) => {
                    url.type[ind] = parseInt(url.type[ind]);
                });
            } else {
                url.type = [parseInt(url.type)];
            }
            this.searchType = url.type;
        }
        if (url.category !== undefined) {
            if (Array.isArray(url.category)) {
                url.category.forEach((itm, ind) => {
                    url.category[ind] = parseInt(url.category[ind]);
                });
            } else {
                url.category = [parseInt(url.category)];
            }
            this.searchCategory = url.category;
        }
        if (url.region !== undefined) {
            if (Array.isArray(url.region)) {
                url.region.forEach((itm, ind) => {
                    url.region[ind] = parseInt(url.region[ind]);
                });
            } else {
                url.region = [parseInt(url.region)];
            }
            this.searchRegion = url.region;
        }
        this.getList(this.pagination.page);
        this.getObjectTypes();
        this.getRegions();
        this.getCategories();
        this.getPages();
        this.getUser();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый объект' : 'Редактирование объекта #' + this.editedItem.id;
        },
    },
    methods: {
        unSearch () {
            this.searchName = '';
            this.searchType = [];
            this.searchCategory = [];
            this.searchRegion = [];
            this.$router.push({ path: 'objects'});
            this.pagination.page = 1;
            this.getList(this.pagination.page);
        },
        doSearch () {
            let queryData = {};
            if (this.searchName.length > 0) {
                queryData.name = this.searchName;
            }
            if (this.searchType.length > 0) {
                queryData.type = this.searchType;
            }
            if (this.searchCategory.length > 0) {
                queryData.category = this.searchCategory;
            }
            if (this.searchRegion.length > 0) {
                queryData.region = this.searchRegion;
            }
            this.$router.push({ path: 'objects', query: queryData});
            this.pagination.page = 1;
            this.getList(this.pagination.page);
        },
        tablePagination (data) {
            this.getList(data.page);
        },
        emptyData () {
            this.editedItem = {
                id: -1,
                name: '',
                userId: -1,
                types: [],
                regions: [],
                categories: [],
                page: -1,
                favorite: false,
                anounce: '',
                location: '',
                area: '',
                guard_zone: '',
                protection_zone: '',
                date: '',
                visit: 0,
                preview: [],
                newPreview: [],
                removePreview: false,
                havePreview: false,
            };
        },
        removePreview () {
            this.editedItem.preview = [];
            this.editedItem.removePreview = true;
            this.editedItem.havePreview = false;
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                let query = { pg, on };
                if (this.searchName.length > 0) {
                    query.name = this.searchName;
                }
                if (this.searchType.length > 0) {
                    query.type = this.searchType;
                }
                if (this.searchCategory.length > 0) {
                    query.category = this.searchCategory;
                }
                if (this.searchRegion.length > 0) {
                    query.region = this.searchRegion;
                }
                const { list, hasNext, totalCount } = await this.$api(`/admin/objects?${new URLSearchParams(query)}`);
                list.forEach((singleNew, index) => {
                    list[index].favorite = singleNew.favorite?'Да':'';
                    let tps = [];
                    list[index].types.forEach((itm, ind) => {
                        tps.push(list[index].types[ind].name);
                    });
                    list[index].types = tps;
                    let rgs = [];
                    list[index].regions.forEach((itm, ind) => {
                        rgs.push(list[index].regions[ind].name);
                    });
                    list[index].regions = rgs;
                    let cts = [];
                    list[index].categories.forEach((itm, ind) => {
                        cts.push(list[index].categories[ind].name);
                    });
                    list[index].categories = cts;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getObjectTypes() {
            try {
                const { list } = await this.$api('/admin/object_types/all');
                this.object_types = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getPages() {
            try {
                const { list } = await this.$api('/admin/pages/all');
                this.pages_list = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getRegions() {
            try {
                const { list } = await this.$api('/admin/regions/all');
                this.regions = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getCategories() {
            try {
                const { list } = await this.$api('/admin/object_categories/all');
                this.categories = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getUser() {
            try {
                let userId = await this.$api('/current');
                this.user = userId.id;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api('/admin/objects/' + item.id);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api('/admin/objects/' + item.id, {
                    method: 'DELETE'
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async uploadPreview() {
            if ((this.editedItem.newPreview !== null) &&
                (this.editedItem.newPreview !== undefined) &&
                (typeof this.editedItem.newPreview === 'object') &&
                (this.editedItem.newPreview.name !== undefined)) {
                const needUpload = [];

                this.editedItem.newPreview.id = this.editedItem.id;
                needUpload.push(this.editedItem.newPreview);
                delete this.editedItem.newPreview;
                const files = await this.$refs.uploader.upload(needUpload);
                for (let f of files) {
                    if (f.id == this.editedItem.id) {
                        let fileData = {
                            tmp: f.tmp,
                            name: f.name,
                            size: Math.ceil(f.size / 1024 / 1024),
                            type: f.type.split('/')[1]
                        };
                        return fileData;
                    }
                }
            }
            return false;
        },
        async save () {
            if (this.editedItem.name.length == 0) {
                this.$error('Название не задано. Объект не может быть сохранён.');
                return;
            }
            // if ((this.editedItem.newPreview == null) || (isNaN(this.editedItem.newPreview.size))) {
            //    this.$error('Изображение для анонса не задано. Объект не может быть сохранён.');
            //    return;
            //}
            const previewFile = await this.uploadPreview();
            // let previewFile = null;
            // if ((this.editedItem.newPreview != null) && (!isNaN(this.editedItem.newPreview.size))) {
            //    previewFile = await this.uploadPreview();
            // }
            let t_ids = []; 
            this.editedItem.types.forEach((type) => {
                if (type.id) {
                    t_ids.push(type.id);
                } else {
                    t_ids.push(type);
                }
            });

            let r_ids = []; 
            this.editedItem.regions.forEach((region) => {
                if (region.id) {
                    r_ids.push(region.id);
                } else {
                    r_ids.push(region);
                }
            });

            let c_ids = []; 
            this.editedItem.categories.forEach((category) => {
                if (category.id) {
                    c_ids.push(category.id);
                } else {
                    c_ids.push(category);
                }
            });

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api('/admin/objects/' + this.editedItem.id, {
                        method: 'PUT',
                        body: {
                            name: this.editedItem.name,
                            favorite: this.editedItem.favorite,
                            user_id: this.user,
                            poster: ((previewFile === false)||(previewFile === null))?'':previewFile.tmp,
                            anounce: this.editedItem.anounce,
                            location: this.editedItem.location,
                            area: this.editedItem.area,
                            guard_zone: this.editedItem.guard_zone,
                            protection_zone: this.editedItem.protection_zone,
                            page: (this.editedItem.page == -1)?null:this.editedItem.page,
                            type_ids: t_ids,
                            region_ids: r_ids,
                            category_ids: c_ids,
                            removePreview: this.editedItem.removePreview,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api('/admin/objects', {
                        method: 'POST',
                        body: {
                            name: this.editedItem.name,
                            favorite: this.editedItem.favorite,
                            user_id: this.user,
                            poster: ((previewFile === false)||(previewFile === null))?'':previewFile.tmp,
                            anounce: this.editedItem.anounce,
                            location: this.editedItem.location,
                            area: this.editedItem.area,
                            guard_zone: this.editedItem.guard_zone,
                            protection_zone: this.editedItem.protection_zone,
                            page: (this.editedItem.page == -1)?null:this.editedItem.page,
                            type_ids: t_ids,
                            region_ids: r_ids,
                            category_ids: c_ids,
                            removePreview: this.editedItem.removePreview,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .text_field_short {
        width: 50%;
    }

    .text_field_with_button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .v-btn {
            margin: auto 0 auto 20px;
        }
    }

    .admin_section_header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: 0 0 0 20px;

        * {
            margin-right: 20px;
        }

        .text-fld {
            max-width: 15%;
            min-width: 120px;
        }
    }
</style>
